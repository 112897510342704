import React from "react"
import HalfStyled from "../styles/half/halfStyles"
import TitleStyled from "../styles/titleStyles"
import ButtonStyled from "../styles/ButtonStyles"
import Link from "gatsby-link"

const HalfLogotype = ({
  backgroundColor,
  heading,
  headingColor,
  description,
  descriptionColor,
  linkText,
  linkIcon,
  position,
}) => {
  const lang =
    typeof window !== "undefined" && window.location.pathname.includes("/en/")
      ? "en"
      : "pl"

  return (
    <HalfStyled backgroundColor={backgroundColor}>
      <div className="half__wrapper">
        <div
          className={`half__content ${
            position === "left" ? "half__content--left" : "half__content--right"
          }`}
        >
          <TitleStyled
            textAlign="center"
            linePosition="center"
            color={headingColor}
            lineColor={backgroundColor}
          >
            <h2 dangerouslySetInnerHTML={{ __html: heading }}></h2>
            <div
              className="halfLogotype__description"
              style={{ color: descriptionColor || "inherit" }}
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          </TitleStyled>

          <div className="HalfButton__button">
            <ButtonStyled dark={true} shadow={true}>
              <Link
                to={
                  lang === "pl"
                    ? "/Logopack-Audience-Network-PL.zip"
                    : "/Logopack-Audience-Network-EN.zip"
                }
                download="Logopack Audience Network.zip"
              >
                {linkText}
                <div className="icon">
                  <img src={linkIcon} alt="" />
                </div>
              </Link>
            </ButtonStyled>
          </div>
        </div>
      </div>
    </HalfStyled>
  )
}

export default HalfLogotype
