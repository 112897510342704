import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import uuid from "react-uuid"
import TitleStyled from "../components/styles/titleStyles"
import MediaStyled from "../components/styles/MediaStyles"
import ButtonStyled from "../components/styles/ButtonStyles"
import MediaBox from "../components/mediaBox"
import myInitObject from "../ultis/variable"
import SEO from "../components/seo"
import Half from "../components/half/index"
import arrowLeft from "../../static/arrow_left.svg"
import arrowRight from "../../static/arrow_rigth.svg"

const IndexPage = ({ data, pageContext }) => {
  const { mediapostsCollection, mediaSingleton } = data.swapi
  const [mediaList, setMediaList] = useState([])
  const [page, setPage] = useState(1)
  const boxesPerPage = 12

  useEffect(() => {
    setMediaList(mediapostsCollection)
  }, [])

  const scrollTo = element =>
    window.scroll({
      behavior: "smooth",
      left: 0,
      top: element.offsetTop - 100,
    })

  const changePage = index => {
    if (index != page) {
      scrollTo(document.querySelector(".container__title"))
      setPage(index)
    }
  }

  const maxPage = Math.ceil(mediaList.length / boxesPerPage)

  return (
    <Layout pageContext={pageContext}>
      <SEO
        title={mediaSingleton.meta_title}
        description={mediaSingleton.meta_description}
        img={mediaSingleton.meta_img ? mediaSingleton.meta_img.path : ""}
        lang="pl"
      />
      <MediaStyled>
        <section className="blogPage casestudiesPage">
          <div className="container container__title">
            <TitleStyled
              textAlign="center"
              linePosition="center"
              color="#000000"
            >
              <h2
                dangerouslySetInnerHTML={{ __html: mediaSingleton.heading }}
              ></h2>
            </TitleStyled>
          </div>
          <div className="container casestudies__feed container__feed blog__feed--3">
            {mediaList
              ? mediaList
                  .slice((page - 1) * boxesPerPage, page * boxesPerPage)
                  .map(item => (
                    <div key={uuid()} className="feed__item">
                      <MediaBox
                        img={
                          item?.mediaimg.path
                            ? myInitObject.cockpitUrl + item.mediaimg.path
                            : null
                        }
                        title={item.title}
                        titleSize="30"
                        description={item.excerpt}
                        date={item.date}
                        link={item.link}
                        linkPostion="bottom"
                        linkText={"Więcej"}
                      />
                    </div>
                  ))
              : null}
          </div>
          {maxPage > 1 && (
            <div className="container media__pagination">
              <ButtonStyled
                dark={true}
                onClick={() => changePage(page == 1 ? 1 : page - 1)}
              >
                <span className="media__page">
                  <img src={arrowLeft} alt="" />
                </span>
              </ButtonStyled>
              {Array.from(Array(maxPage).keys()).map(el => {
                el = el + 1
                return (
                  <ButtonStyled dark={true} onClick={() => changePage(el)}>
                    {el == page ? (
                      <span className="media__page active">{el}</span>
                    ) : (
                      <span className="media__page">{el}</span>
                    )}
                  </ButtonStyled>
                )
              })}
              <ButtonStyled
                dark={true}
                onClick={() => changePage(page == maxPage ? maxPage : page + 1)}
              >
                <span className="media__page">
                  <img src={arrowRight} alt="" />
                </span>
              </ButtonStyled>
            </div>
          )}
        </section>
        <Half key={uuid()} half={mediaSingleton.half} />
      </MediaStyled>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query mediaPL {
    swapi {
      mediaSingleton(lang: "pl") {
        meta_title
        meta_description
        meta_img {
          path
        }
        heading
        half
      }
      mediapostsCollection(lang: "pl") {
        title
        excerpt
        mediaimg {
          path
        }
        mediaimgalt
        featured
        date
        link
      }
    }
  }
`
