import React from "react"
import Link from "gatsby-link"
import ButtonStyled from "./styles/ButtonStyles"
import MediaBoxStyled from "./styles/MediaBoxStyles"

const MediaBox = ({
  img,
  title,
  date,
  description,
  link,
  linkText,
  titleSize,
  linkPostion,
}) => {
  return (
    <>
      {linkText ? (
        <a href={link} rel="nofollow">
          <MediaBoxStyled titleSize={titleSize} img={img}>
            <div className="media__box">
              {img ? <div className="media__box__img"></div> : ""}
              {title ? (
                <p
                  className="media__box__title"
                  dangerouslySetInnerHTML={{ __html: title }}
                ></p>
              ) : null}
              {linkPostion != "bottom" ? (
                <div className="media__box__button">
                  <ButtonStyled dark={true}>
                    <a href={link} target="_blank">
                      {linkText}
                    </a>
                  </ButtonStyled>
                </div>
              ) : null}
              {/* { date ? (
                <p className="media__box__meta">
                    {date ? (
                    <span className="media__box__date">{date}</span>
                  ) : null}
                </p>
              ) : null} */}
              {description ? (
                <div
                  className="media__box__description"
                  dangerouslySetInnerHTML={{ __html: description }}
                ></div>
              ) : null}
              {linkPostion == "bottom" ? (
                <div className="media__box__button">
                  <ButtonStyled dark={true}>
                    <a href={link} target="_blank">
                      {linkText}
                    </a>
                  </ButtonStyled>
                </div>
              ) : null}
            </div>
          </MediaBoxStyled>
        </a>
      ) : (
        <a href={link} rel="nofollow">
          <MediaBoxStyled titleSize={titleSize} img={img}>
            <div className="media__box">
              <div className="media__box__img"></div>
              {title ? (
                <p
                  className="media__box__title"
                  dangerouslySetInnerHTML={{ __html: title }}
                ></p>
              ) : null}
              {/* {date ? (
                <p className="media__box__meta">
                  {date ? (
                    <span className="media__box__date">{date}</span>
                  ) : null}
                </p>
              ) : null} */}
              {description ? (
                <div
                  className="media__box__description"
                  dangerouslySetInnerHTML={{ __html: description }}
                ></div>
              ) : null}
            </div>
          </MediaBoxStyled>
        </a>
      )}
    </>
  )
}

export default MediaBox
