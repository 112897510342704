import React from "react"
import HalfStyled from "../styles/half/halfStyles"
import TitleStyled from "../styles/titleStyles"
import NewsletterInput from "../newsletterInput"

const HalfNewsletter = ({
  heading,
  descirption,
  placeholder,
  textButton,
  position,
  backgroundColor,
  headingColor,
  checkbox,
  errorCheckbox,
  errorInput,
}) => {
  return (
    <HalfStyled backgroundColor={backgroundColor} colorText={headingColor}>
      <div className="half__wrapper">
        <div
          className={`half__content ${
            position === "left" ? "half__content--left" : "half__content--right"
          }`}
        >
          <TitleStyled
            textAlign="center"
            linePosition="center"
            color={headingColor}
            lineColor={headingColor}
          >
            <h2 dangerouslySetInnerHTML={{ __html: heading }}></h2>
          </TitleStyled>
          <p
            className="half__description"
            dangerouslySetInnerHTML={{ __html: descirption }}
          ></p>
          <NewsletterInput
            textButton={textButton}
            color={headingColor}
            placeholder={placeholder}
            checkbox={checkbox}
            errorCheckbox={errorCheckbox}
            errorInput={errorInput}
          />
        </div>
      </div>
    </HalfStyled>
  )
}

export default HalfNewsletter
