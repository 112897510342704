import React from "react"
import uuid from "react-uuid"
import HalfImage from "./halfImage"
import HalfNumbers from "./halfNumbers"
import HalfNewsletter from "./HalfNewsletter"
import HalfDescription from "./halfDescription"
import myInitObject from "../../ultis/variable"
import HalfButton from "./halfButton"
import HalfLogotype from "./halfLogotype"

function selectCompoment(item, position) {
  switch (item.component) {
    case "halfNewsletter":
      return (
        <>
          <HalfNewsletter
            key={uuid()}
            heading={item.settings.heading}
            descirption={item.settings.descirption}
            placeholder={item.settings.placeholder}
            textButton={item.settings.textButton}
            position={position}
            backgroundColor={item.settings.backgroundColor}
            headingColor={item.settings.headingColor}
            checkbox={item.settings.checkbox}
            errorCheckbox={item.settings.errorCheckbox}
            errorInput={item.settings.errorInput}
          />
        </>
      )

    case "halfButton":
      return (
        <>
          <HalfButton
            key={uuid()}
            backgroundColor={item.settings.background}
            heading={item.settings.heading}
            headingColor={item.settings.headingColor}
            link={item.settings.link}
            linkText={item.settings.linkText}
            position={position}
          />
        </>
      )

    case "halfLogotype":
      return (
        <>
          <HalfLogotype
            key={uuid()}
            backgroundColor={item.settings.background}
            heading={item.settings.heading}
            headingColor={item.settings.headingColor}
            description={item.settings.description}
            descriptionColor={item.settings.descriptionColor}
            linkText={item.settings.linkText}
            linkIcon={
              item.settings.linkIcon
                ? myInitObject.cockpitUrl + item.settings.linkIcon.path
                : ""
            }
            position={position}
          />
        </>
      )

    case "halfDescription":
      return (
        <>
          <HalfDescription
            key={uuid()}
            backgroundColor={item.settings.backgroundColor}
            heading={item.settings.heading}
            headingColor={item.settings.headingColor}
            description={item.settings.description}
            descriptionColor={item.settings.descriptionColor}
            position={position}
            headingPostion={item.settings.headingPostion}
            showLine={item.settings.showLine}
            lineColor={item.settings.lineColor}
            nameClass={item.settings.class}
            id={item.settings.id}
          />
        </>
      )

    case "halfNumbers":
      return (
        <>
          <HalfNumbers
            key={uuid()}
            backgroundColor="#fff"
            headingColor="#000"
            descriptionColor="#000"
            position={position}
            numbers={item.settings.dmpNubmer}
            borderColor={item.settings.borderColor}
          />
        </>
      )

    case "halfImage":
      return (
        <>
          <HalfImage
            key={uuid()}
            backgroundImage={myInitObject.cockpitUrl + item.settings.img.path}
            hideMobile={item.settings.hideMobile}
          />
        </>
      )

    default:
  }
}

const Half = half => {
  return (
    <section className="half">
      {half.half.map(items => {
        const left = items.settings.contentLeft
        const right = items.settings.contentRight
        const leftLength = left.length
        const rightLength = right.length
        const count = leftLength > rightLength ? leftLength : rightLength
        return (
          <>
            {Array(count)
              .fill("")
              .map((item, index) => {
                return (
                  <>
                    {selectCompoment(left[index], "left")}
                    {selectCompoment(right[index], "right")}
                  </>
                )
              })}
          </>
        )
      })}
    </section>
  )
}

export default Half
